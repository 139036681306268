<template>
  <div class="folha">
    <table>
      <tbody>
        <tr>
          <td colspan="3">
            <center>
              <br />
              <h1>OPORTUNIDADE</h1>
              <h1>IMPERD&Iacute;VEL</h1>
            </center>

            <div style="margin-button: 5px">
              <div class="col1">
                <img
                  class="pb"
                  src="../../assets/logoEasy2019.png"
                  style="max-height: 60px"
                />
              </div>
              <div class="col2">
                <img
                  class="pb"
                  src="../../assets/oitv.png"
                  style="max-height: 80px"
                />
              </div>
            </div>
            <div class="space"></div>
            <div style="width: 96%; margin: 0 auto">
              Rio de Janeiro, {datadodia} <br />
              {{ nomedevedor }}, <br />
              <b>A Oi</b> nos confiou a responsabilidade da cobran&ccedil;a de
              seu d&eacute;bito, referente &agrave;(s) fatura(s) relacionada(s)
              <br />
              abaixo. Pagando&ndash;as, voc&ecirc; quita este d&eacute;bito
              junto a <b>Oi</b> e evita restri&ccedil;&otilde;es, relativas a
              estas faturas, <br />
              junto aos &Oacute;rg&atilde;os de Prote&ccedil;&atilde;o ao
              Cr&eacute;dito (SPC E SERASA). <br />
              Contrato n&ordm;: {{ contrato }}
            </div>

            <table class="semBorda" style="width: 96%">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="border: 1px solid black; background: #ccc">
                  Valor a Pagar
                </td>
                <td></td>
              </tr>
            </table>

            <table class="semBorda" style="width: 96%">
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="border: 1px solid black">
                  R$ {{ valor | dinheiro }}
                </td>
                <td></td>
              </tr>
            </table>

            <table style="width: 96%">
              <tr>
                <td>Fatura</td>
                <td>Vencimento</td>
                <td>Valor Original</td>
                <td style="min-width: 10cm">Linha Digit&aacute;vel</td>
              </tr>
              <tr>
                <td>{{ contrato }}</td>
                <td>{{ venc_orig | dataF }}</td>
                <td>R$ {{ valor | dinheiro }}</td>
                <td style="min-width: 10cm">{{ barcodeVal }}</td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="min-width: 10cm"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="min-width: 10cm"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="min-width: 10cm"></td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td style="min-width: 10cm"></td>
              </tr>

              <tr>
                <td style="background: #ccc"></td>
                <td>Valor Total</td>
                <td>R$ {{ valor | dinheiro }}</td>
                <td style="min-width: 10cm; background: #ccc"></td>
              </tr>
            </table>

            <div class="space"></div>
            <div
              style="
                  width: 96%;
                  margin: 0 auto;
                  font-size: 110%;
                  line-height: 115%;
                  text-align: center;
                "
            >
              <font style="font-size: 110%"> ATEN&Ccedil;&Atilde;O: </font>
              <br />
              Vencimento: {{ vencimento }} <br /><br />

              <u style="font-size: 130%">CENTRAL DE RELACIONAMENTO</u><br />
              Tel: 031(21) 2206-4873 de 2&ordf; a 6&ordf;&ndash;feira: das 8h
              &agrave;s 20h <br />
              S&aacute;bado: das 8h &agrave;s 14:00h ( Hor&aacute;rio de
              Bras&iacute;lia &nbsp; DF) &nbsp;&nbsp;
              <b>E&ndash;mail: boleto@easboleto.com.br</b> <br />
              EasyCob Consultoria, Treinamento e Assessoria Empresarial Ltda.
            </div>
            <div class="space"></div>
            <div
              style="
                  width: 96%;
                  margin: 0 auto;
                  border: 1px solid #000;
                  font-size: 90%;
                  padding: 3px;
                "
            >
              "Importante: O pagamento do boleto pode ser feito em qualquer
              estabelecimento autorizado (Bancos, Casa Lot&eacute;ricas,
              Correios e outros. Caso opte em realizar o pagamento numa Casa
              Lot&eacute;rica, basta informar o seu contrato. Se voc&ecirc;
              j&aacute; fez o(s) pagamento(s), por favor, desconsidere esta
              carta. O(s) valor(es) pago(s) pode(m) levar at&eacute; 5 dias pra
              ser(em)recebido(s) pela OI."
            </div>
            <div
              style="
                  width: 96%;
                  margin: 0 auto;
                  padding: 3px;
                  text-align: center;
                "
            >
              Credor: OI MOVEL S.A &ndash; CNPJ: 05.423.963/0001&ndash;11 &nbsp;
              &nbsp; Inscr. Estadual: 0744135600193 <br />
              Setor Comercial Norte, s/n &ndash; QD 03 BL A T&eacute;rreo
              &ndash; Asa Norte &ndash; CEP: 70.713 &ndash; 900 &ndash;
              Bras&iacute;lia &ndash; DF
            </div>
            <div class="space"></div>
            <table style="width: 96%">
              <tr>
                <td style="min-width: 10cm"><h2>DTH</h2></td>
                <td>
                  Contrato
                  <h3>{{ contrato }}</h3>
                </td>
                <td>
                  Vencimento
                  <h3>{{ vencimento }}</h3>
                </td>
                <td>
                  Total a Pagar
                  <h3>R$ {{ valor | dinheiro }}</h3>
                </td>
              </tr>
            </table>

            <div class="space"></div>
            <div style="margin-button: 10px">
              <div class="col1">
                <img
                  class="pb"
                  src="../../assets/logoEasy2019.png"
                  style="max-height: 28px"
                />
              </div>
              <div class="col2">
                <img
                  class="pb"
                  src="../../assets/oitv.png"
                  style="max-height: 40px"
                />
              </div>
            </div>

            <table style="width: 96%">
              <tr>
                <td colspan="3">
                  O pagamento desta fatura deve ser efetuado na rede
                  banc&aacute;ria conveniada, ou nos estabelecimentos
                  credenciados pela Oi.
                </td>
              </tr>
              <tr>
                <td colspan="2"><h2>DTH</h2></td>
                <td>
                  CPF/CNPJ
                  <h3>{{ cpf }}</h3>
                </td>
              </tr>
              <tr>
                <td>
                  N&ordm; Contrato OI
                  <h3>{{ contrato }}</h3>
                </td>
                <td>
                  Vencimento
                  <h3>{{ vencimento }}</h3>
                </td>
                <td>
                  Valor a pagar
                  <h3>R$ {{ valor | dinheiro }}</h3>
                </td>
              </tr>
              <tr>
                <td colspan="3">
                  <div style="width: 33%; float: left; min-height: 20px">
                    Cliente <br />
                    E00001
                  </div>
                  <div style="width: 33%; float: left; min-height: 20px">
                    {{ nomedevedor }} <br /><br />
                    {{ endereco }} <br />
                    {{ bairro }} <br />
                    {{ cidade }} – {{ uf }} <br />
                    {{ cep }}
                  </div>
                  <div style="width: 33%; float: left; min-height: 20px"></div>
                  <div class="space"></div>
                </td>
              </tr>
            </table>

            <div class="space"></div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="space">
      {{ barcodeVal }}
      <div id="codb"></div>
    </div>
  </div>
</template>

<script>
import barcode from "../../conf/barcode";
//import utilidade from "../../conf/utilitario";
export default {
  name: "OiTvDth2",
  data() {
    return {
      nomedevedor: "",
      contrato: "",
      cpf: "",
      nossonumero: "",
      valor: "",
      vencimento: "",
      venc_orig: "",
      barcodeVal: "",
      barcordeVal2: "",
      codB: "",
      faturas: "",
      faturasTodas: [],
      endereco: "",
      bairro: "",
      cidade: "",
      uf: "",
      cep: "",
      cgc: "",
    };
  },
  filters: {
    dinheiro: function(value) {
      return parseFloat(value)
        .toFixed(2)
        .replace(".", ",");
    },
    dataF: function(d2) {
      let d = d2.substr(0, 10);
      return d.substr(8, 2) + "/" + d.substr(5, 2) + "/" + d.substr(0, 4);
    },
  },
  methods: {
    voltarDividas() {
      this.$router.push("dividas");
    },
  },
  async mounted() {
    let boleto = this.$store.state.boleto.data;
    barcode(window.$);
    window.$("#codb").barcode({ code: `${boleto.codB}`, crc: false }, "int25", {
      barWidth: 1,
      barHeight: 50,
      showHRI: false,
    });
  },
  async beforeMount() {
    let boleto = this.$store.state.boleto.data;

    this.nomedevedor = boleto.nomedevedor;
    this.contrato = boleto.contrato;
    this.cpf = boleto.cpf;
    this.nossonumero = boleto.nossonumero;
    this.valor = boleto.valor;
    this.vencimento = boleto.vencimento;
    this.venc_orig = boleto.venc_orig;
    this.barcodeVal = boleto.barcodeVal;
    this.barcordeVal2 = boleto.barcordeVal2;
    this.codB = boleto.codB;
    this.faturas = boleto.faturas;
    this.faturasTodas = boleto.faturasTodas;
    this.endereco = boleto.endereco;
    this.bairro = boleto.bairro;
    this.cidade = boleto.cidade;
    this.uf = boleto.uf;
    this.cep = boleto.cep;
    this.cgc = boleto.cgc;

    await this.$store.dispatch("setMostraBotoes", {
      imprimir: true,
      voltar: false,
    });
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 10px;
}
a img,
img {
  border: none;
}
ol,
ul {
  list-style: none;
  border: 0;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}
audio:not([controls]) {
  display: none;
  height: 0;
}
[hidden],
template {
  display: none;
}
a {
  background-color: transparent;
}
a:active,
a:hover {
  outline: 0;
}
b,
strong {
  font-weight: bold;
}
hr {
  border: 0px none;
  height: 1px;
  background-image: -moz-linear-gradient(
    left center,
    transparent,
    rgba(0, 0, 0, 0.75),
    transparent
  );
  margin-top: 4px;
  margin-bottom: 4px;
}
button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
}
button[disabled],
html input[disabled] {
  cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box; /* 1 */
  padding: 0; /* 2 */
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}
input[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box; /* 2 */
  box-sizing: content-box;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
h1 {
  font-size: 18px;
}
h2 {
  font-size: 16pt;
}
/* --------------------------------
		geral
--------------------------------- */
html,
body {
  height: 100%;
  background: #f9f9f9;
}
i {
  color: red;
  font-weight: normal;
}
.space {
  clear: both;
  padding: 5px;
  width: 17cm;
  margin: 0 auto;
}
.content {
  margin: 0 auto;
  width: 17cm;
  position: relative;
}
.col1 {
  width: 48%;
  float: left;
  text-align: left;
  padding-right: 1%;
}
.col2 {
  width: 48%;
  float: right;
  text-align: right;
  padding-left: 1%;
}
.long {
  min-height: 7.5cm;
}
.medium {
  min-height: 3cm;
}
.footer {
  position: relative;
  margin-top: 40px;
}
hr {
  border: 0;
  background-color: #fff;
  border-top: 1px dashed #000;
}
ul {
  margin: 0;
  padding: 0;
}
ul li {
  list-style-type: none;
}
code {
  color: #000;
  float: none;
  background: #fff none repeat scroll 0% 0%;
  font-weight: normal;
  font-size: 10px;
}
a {
  color: #2b75bf;
}
a:hover {
  color: #284a6b;
  text-decoration: none;
}
a:focus {
  color: #284a6b;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 5px;
}
/* --------------------------------
		Linhas tabelas e html
--------------------------------- */
table {
  font-weight: 700;
  width: 17cm;
  margin: 0 auto;
  border-collapse: collapse; /* CSS2 */
  background: #fff;
}
table b {
  font-weight: 800;
}
table td {
  vertical-align: top;
  min-width: 4cm;
  border: 2px solid black;
  padding: 5px;
  height: 18px;
}
table th {
  border: 12px solid black;
  background: #fff;
  height: 18px;
}
.semBorda td {
  border: none;
}
/* --------------------------------
			formularios
--------------------------------- */
input {
  float: right;
  background: #3498db;
  margin: 5px;
  border-radius: 4px;
  border: 0;
  color: #fff;
  font-size: 16px;
  padding: 8px;
  text-decoration: none;
  cursor: pointer;
}
input:hover {
  background: #1179be;
}
/* ==========================================================================
   Estilos para impressão
   ========================================================================== */
@media print {
  * {
    background: transparent !important;
    color: #000 !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  }
  body {
    font: 10pt Georgia, "Times New Roman", Times, serif;
    color: #000;
  }

  h1 {
    font-size: 15pt;
  }
  h2 {
    font-size: 13pt;
  }
  h3 {
    font-size: 14pt;
  }
  q:after {
    content: " (" attr(cite) ")";
  }
  a,
  a:visited {
    text-decoration: underline;
  }
  a:link:after,
  a:visited:after {
    content: " (" attr(href) ") ";
  }
  p a {
    word-wrap: break-word;
  }
  p {
    widows: 3;
  }
  p {
    orphans: 3;
  }
  .page-break {
    page-break-before: always;
  }
  .notprint {
    visibility: hidden;
    display: none;
  }
  .pb {
    filter: -ms-grayscale(100%) !important;
    filter: -moz-grayscale(100%) !important;
    filter: -webkit-grayscale(100%) !important;
    filter: grayscale(100%) !important;
  }
}
.folha {
  page-break-after: always;
  max-height: 27.5cm;
}
</style>
